<template>
  <div>
    <GenericForm
      title="motivos de retorno"
      previousRoute="motivoRetorno"
      editRoute="motivoRetornoEditar"
      :model="model"
      :route="motivoRetornoRoute"
      :editing="editing"
      @updateModel="updateModel"
      idModel="id_motivo_retorno"
      :permissionsToWrite="['rw_material']"
      :permissionsToEdit="['re_material']"
      :permissionsToDelete="['rd_material']"
    >
      <template v-slot="{ validateState, isBusy }">
        <b-row>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Motivo*" label-for="motivo">
              <b-form-input
                id="motivo"
                v-model="model.motivo"
                autocomplete="off"
                class="invision-input"
                name="motivo"
                :disabled="isBusy"
                v-validate="{ required: true }"
                :state="validateState('motivo')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Descrição" label-for="descricao">
              <b-form-input
                id="descricao"
                v-model="model.descricao"
                autocomplete="off"
                class="invision-input"
                name="descricao"
                :disabled="isBusy"
                v-validate="{ required: false }"
                :state="validateState('descricao')"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </GenericForm>
  </div>
</template>

<script>
import GenericForm from '@/components/Form/GenericForm';

export default {
  components: {
    GenericForm,
  },

  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      model: {
        id_motivo_retorno: this.$route.params.id || -1,
        motivo: '',
        descricao: '',
      },

      motivoRetornoRoute: 'motivoRetorno',
    };
  },

  methods: {
    updateModel(model) {
      this.model = model;
    },
  },
};
</script>
